import React, { Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import { PrimaryButton } from '../../Common';

const SessionTimeoutModal = ({
    setIsUserTimedOut,
}: {
    setIsUserTimedOut: Dispatch<SetStateAction<boolean>>;
}) => {
    const navigate = useNavigate();

    return (
        <div className="!top-0 bg-[#F8FBEACC] fixed left-0 w-full !z-[1000] h-full flex items-center justify-center">
            <div className="bg-white p-8 rounded-lg shadow-lg text-center w-96">
                <h2 className="text-2xl font-poppins-bold text-red-500 mb-4">
                    Session Timed Out
                </h2>
                <p className="text-lg font-poppins-regular text-black mb-6">
                    Your session has expired due to inactivity. Please log in
                    again to continue.
                </p>
                <PrimaryButton
                    onClick={() => {
                        navigate('/');
                        setIsUserTimedOut(false);
                    }}
                    isDrawerButton
                    color="#2E672F"
                    className="w-full"
                    type="button"
                    name="Log In Again"
                    variant="filled"
                />
            </div>
        </div>
    );
};

export default SessionTimeoutModal;
