import {
    ISignInUser,
    IVerifyOTP,
    ICreateNewPasswordReq,
    IForgotPasswordReq,
    IVerifySignupTokenReq,
    IResetPasswordReq,
    ICreateGroupReq,
    ISaveGroupReq,
    IPreconditionRulesReq,
    IGetAllRuleByCompanyId,
    IGetPreConditionReq,
    IDisableEnableGroupRule,
    IDeleteGroupRule,
    ISaveRule,
    IUpdateOrder,
    IUpdateGroupOrder,
    IUpdateCompanyPayload,
    IGetApiKeysPayload,
    IMerchantUserPayload,
    IMerchant,
} from 'data/types/request';
import {
    ICreateGroupData,
    ICreateNewPasswordRes,
    IForgotPasswordRes,
    IGetAllGroups,
    IGetBrandData,
    IGetPageAreaData,
    IGroupRuleDataByCompanyRes,
    IResetPasswordRes,
    ISignInRes,
    ITransactionRes,
    IValidateResetToken,
    IVerifySignupRes,
} from 'data/types/response';
import axios from './axios';

/** **************************
 *            auth           *
 *************************** */
export const signIn = (requestObj: ISignInUser): Promise<ISignInRes> =>
    axios.post('/users/signin', requestObj);

export const getCurrentUser = (token: string): Promise<any> =>
    axios.get(`users/get-current-user/${token}`);

export const verifyOtp = (
    requestObj: IVerifyOTP,
    token: string
): Promise<any> =>
    axios.post('users/verify-otp', requestObj, {
        headers: { Authorization: `Bearer ${token}` },
    });

export const verifySignupToken = (
    requestObj: IVerifySignupTokenReq,
    token: string
): Promise<IVerifySignupRes> =>
    axios.post('/users/verify-signup-token', requestObj, {
        headers: { Authorization: `Bearer ${token}` },
    });

export const forgotPassword = (
    requestObj: IForgotPasswordReq
): Promise<IForgotPasswordRes> =>
    axios.post('/users/forgot-password', requestObj);

export const verifyResetToken = (
    requestObj: IVerifySignupTokenReq
): Promise<IValidateResetToken> =>
    axios.post('/users/verify-reset-token', requestObj);

export const resetPassword = (
    requestObj: IResetPasswordReq
): Promise<IResetPasswordRes> =>
    axios.post('/users/reset-password', requestObj);

export const createNewPassword = (
    requestObj: ICreateNewPasswordReq
): Promise<ICreateNewPasswordRes> =>
    axios.post('/users/set-password', requestObj);

/** **************************
 *            Brands           *
 *************************** */

export const getAllBrands = (payload: any): Promise<IGetBrandData> =>
    axios.post('/brands/get', payload);
export const createBrand = (requestObj: any): Promise<IGetBrandData> =>
    axios.post('/brands/create', requestObj);
export const updateBrand = (
    brandId: string,
    requestObj: any
): Promise<IGetBrandData> => axios.put(`/brands/update/${brandId}`, requestObj);
export const bulkActionForBrand = (requestObj: any): Promise<any> =>
    axios.post('/brands/bulk-action', requestObj);
export const inActiveBrand = (brandId: string): Promise<any> =>
    axios.get(`/brands/inactive/${brandId}`);

export const updateBrandStatus = (
    brandId: string,
    requestObj: any
): Promise<any> => axios.put(`/brands/update-status/${brandId}`, requestObj);

// export const deleteBrand = (brandId: string): Promise<any> =>
//     axios.delete(`/brands/delete/${brandId}`);

/** **************************
 *            PageAreas           *
 *************************** */

export const getAllPageAreas = (): Promise<IGetPageAreaData> =>
    axios.get('/page-areas/get-all');

/** **************************
 *            Groups           *
 *************************** */

export const createGroup = (
    requestObj: ICreateGroupReq
): Promise<ICreateGroupData> => axios.post('/groups/create', requestObj);

export const getGroupData = (payload: any): Promise<IGetAllGroups> =>
    axios.post('/groups/get-all', payload);
export const inActiveGroup = (groupId: string): Promise<any> =>
    axios.get(`/groups/inactive/${groupId}`);

export const bulkActionForGroups = (requestObj: {
    groupIds: string[];
    type: string;
}): Promise<any> => axios.post('/groups/bulk-action', requestObj);

// export const deleteGroup = (groupId: string): Promise<any> =>
//     axios.delete(`/groups/delete/${groupId}`);

export const updateGroup = (groupId: string, requestObj: any): Promise<any> =>
    axios.put(`/groups/update/${groupId}`, requestObj);

export const updateGroupStatus = (
    groupId: string,
    requestObj: any
): Promise<any> => axios.put(`/groups/update-status/${groupId}`, requestObj);

/** **************************
 *            User           *
 *************************** */

export const inviteUser = (requestObj: any): Promise<any> =>
    axios.post('/users/invite-user', requestObj);

// export const deleteUser = (userId: string): Promise<any> =>
//     axios.delete(`/users/delete/${userId}`);

export const bulkActionForUser = (requestObj: {
    userIds: string[];
    type: string;
}): Promise<any> => axios.post('/users/bulk-action', requestObj);

export const resetFailedAttempts = (requestObj: {
    emails: string[];
}): Promise<any> => axios.post('/users/resetFailedAttempts', requestObj);

export const updateUserStatus = (
    userId: string,
    requestObj: any
): Promise<any> => axios.put(`/users/update-status/${userId}`, requestObj);

export const updateUser = (userId: string, requestObj: any): Promise<any> =>
    axios.put(`/users/update`, requestObj);

export const getUserByCompany = (requestObj: any): Promise<any> =>
    axios.post('/users/get-users-by-company', requestObj);

/** **************************
 *            Company           *
 *************************** */

export const createCompany = (requestObj: any): Promise<any> =>
    axios.post('/company/create', requestObj);
export const bulkActionForCompany = (requestObj: any): Promise<any> =>
    axios.post('/company/bulk-action', requestObj);
export const updateCompanyStatus = (
    companyId: string,
    requestObj: any
): Promise<any> => axios.put(`/company/update-status/${companyId}`, requestObj);

// export const deleteCompany = (companyId: string): Promise<any> =>
//     axios.delete(`/company/delete/${companyId}`);

export const updateCompany = (
    companyId: string,
    requestObj: IUpdateCompanyPayload
): Promise<any> => axios.put(`/company/update/${companyId}`, requestObj);

export const getCompanies = (query: string): Promise<any> =>
    axios.get(`/company/get?${query}`);

export const getApiKeys = (requestObj: {
    payload: IGetApiKeysPayload;
}): Promise<any> => axios.post(`/company/api-keys`, requestObj);

export const getCompanyBrands = (): Promise<any> =>
    axios.get('/company/companyandbrands');
/** **************************
 *            Audit           *
 *************************** */
export const getAuditUser = (requestObj: any): Promise<any> =>
    axios.post('/audit/get-audit-logs', requestObj);

/** **************************
 *            Permissions           *
 *************************** */
export const getPermissions = (permissionId: string): Promise<any> =>
    axios.get(`/permissions/get-by-id/${permissionId}`);
/** **************************
 *            Permissions           *
 *************************** */

/** **************************
 *            Transaction           *
 *************************** */

export const getTransaction = (requestObj: any): Promise<ITransactionRes> =>
    axios.post('/transactions/getTransactions', requestObj);

export const getPaymentApproval = (requestObj: any): Promise<any> =>
    axios.post('/transactions/getApprovalTransactions', requestObj);

export const approveTransactions = (requestObj: any): Promise<any> =>
    axios.post('/transactions/payout/approve', requestObj);

export const processTrustPayment = (requestObj: any): Promise<any> =>
    axios.post('/transactions/trust/payin', requestObj);
export const processPaymodumPayment = (requestObj: any): Promise<any> =>
    axios.post('/transactions/paymodum/payin', requestObj);

export const processPaymodumPaymentPayout = (requestObj: any): Promise<any> =>
    axios.post('/transactions/paymodum/payout', requestObj);

export const cleoPayment = (requestObj: any): Promise<any> =>
    axios.post('/transactions/cleo/payin', requestObj);
export const cleoPaymentPayout = (requestObj: any): Promise<any> =>
    axios.post('/transactions/cleo/payout', requestObj);

export const getCleoPayInCallback = (id: string): Promise<any> =>
    axios.post(`/transactions/cleo/payin/callback/${id}`, {});

export const createPaymentProvider = (requestObj: any): Promise<any> =>
    axios.post(`configuration/create`, requestObj);

export const getPaymentProvider = (companyId: string): Promise<any> =>
    axios.get(`/configuration/get/${companyId}`);

export const getPaymodumeTransaction = (requestObj: any): Promise<any> =>
    axios.post(`/transactions/getPaymodumTransactions`, requestObj);

export const getFilterColumn = (requestObj: any): Promise<any> =>
    axios.post(`/users/setFilterColumns`, requestObj);

export const britePaymentPayIn = (req: any): Promise<any> =>
    axios.post(`/paymentProvider/brite/instantPaymentCreateDesposit`, req);

export const britePaymentPayOut = (): Promise<any> =>
    axios.post(`/paymentProvider/brite/instantPaymentCreateWithDrawal`);

/** **************************
 *            Rule Engine     *
 *************************** */

export const saveGroup = (requestObj: ISaveGroupReq): Promise<any> =>
    axios.post(`/ruleEngine/saveGroupRule`, requestObj);

export const getAllGroupRulesByCompany = (
    req: IGetAllRuleByCompanyId
): Promise<IGroupRuleDataByCompanyRes> =>
    axios.post(`/ruleEngine/getAllGroupRulesByCompany`, req);

export const savePreconditionRule = (
    req: IPreconditionRulesReq
): Promise<any> => axios.post(`/ruleEngine/savePrecondition`, req);

export const getPreconditionRule = (req: IGetPreConditionReq): Promise<any> =>
    axios.post(`/ruleEngine/getPreconditionsByGroupId`, req);

export const saveDisableEnableGroupRule = (
    req: IDisableEnableGroupRule
): Promise<any> => axios.post(`/ruleEngine/disableEnableGroupRule`, req);

export const deletedGroupRule = (req: IDeleteGroupRule): Promise<any> =>
    axios.post(`/ruleEngine/deleteGroupRule`, req);

export const getGroupRule = (id: string): Promise<any> =>
    axios.get(`/ruleEngine/rule/getbygroupId/${id}`);

export const getViewRule = (id: string): Promise<any> =>
    axios.get(`/ruleEngine/rule/get/${id}`);

export const deleteRule = (id: string): Promise<any> =>
    axios.delete(`/ruleEngine/rule/delete/${id}`);
export const saveRule = (req: ISaveRule): Promise<any> =>
    axios.post(`/ruleEngine/rule/create`, req);

export const updateRule = (req: ISaveRule, id: string): Promise<any> =>
    axios.patch(`/ruleEngine/rule/update/${id}`, req);

export const updateRuleOrder = (req: IUpdateOrder): Promise<any> =>
    axios.post(`/ruleEngine/rule/updateorder`, req);

export const updateGroupOrder = (req: IUpdateGroupOrder): Promise<any> =>
    axios.post(`/ruleEngine/rule/updateGroupOrder`, req);

export const duplicateRule = (id: string): Promise<any> =>
    axios.patch(`/ruleEngine/rule/duplicate/${id}`);

export const createMerchant = (req: IMerchantUserPayload): Promise<any> =>
    axios.post('/merchant-players/create-profile', req);

export const getMerchant = (req: IMerchant): Promise<any> =>
    axios.post('/merchant-players/get-profile', req);

export const updateMerchant = (id: string, req: IMerchant): Promise<any> =>
    axios.put(`/merchant-players/update-profile/${id}`, req);
